import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    // 加载页面
    name: 'login',
    component: () => import('./view/login.vue')
  },
  {
    // 首页
    name: 'home',
    component: () => import('./view/home.vue')
  },
  {
    // 商家列表
    name: 'shop/list',
    component: () => import('./view/shop/list.vue')
  },
  {
    // 商家详情
    name: 'shop/detail',
    component: () => import('./view/shop/detail.vue')
  },
  {
    // 商家详情
    name: 'shop/mingdian',
    component: () => import('./view/shop/mingdianList.vue')
  },
  {
    // 美食地图
    name: 'map',
    component: () => import('./view/map/index.vue')
  },
  {
    // 年夜饭
    name: 'shop/year',
    component: () => import('./view/shop/yearfood.vue')
  },
  {
    // 视频详情
    name: 'shop/videoDetail',
    component: () => import('./view/shop/videoDetail.vue')
  },
  {
    // 寻味成华
    name: 'shop/videoList',
    component: () => import('./view/shop/videoList.vue')
  },
  {
    // 寻味成华
    name: 'showImg',
    component: () => import('./view/shop/imageShow.vue')
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
  // mode: 'history',
  base: 'page',
  routes
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  next();
});

router.afterEach(() => {
  // 让页面回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

export {
  router
};
