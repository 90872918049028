/**
 * Created by zxw
 * auth.js使用到的方法（用于多次调用和其它地方调用）
 */
// 引入ajax库
import axios from 'axios';

const config = {
  // 项目目录
  publicPath: "/page",
  // 接口地址前缀
  serviceHost: "/api/gateway",
}

/**
 * 从gateway获取数据
 *
 */
function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

function param(json) {
  if (!json) return '';
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return '';
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    })
  ).join('&');
}

function getData(axiosUrl, axiosType, parameter, callback, allowCancel, type) {
  /*if(type === "node"){
    // 目前仅支持get请求
    axiosType = "get";
    axiosUrl = config.dir + "/api/node" + functionName;
  }*/
  let originCallback = callback;
  // 回调函数处理
  callback = function (result) {
    let response;

    try {
      response = originCallback.call(null, result);
    } catch (error) {
      console.error('[=> api.js] 回调函数错误--> recommend $ajax.gateway(url, parameter).then(resolved)', error);
    }

    if (response) {
      return response;
    } else {
      return result;
    }
  };
  // Api
  let self = this;
  typeof self.before === 'function' &&
  self.before(axiosUrl, parameter, callback, allowCancel, type);

  return new Promise((resolved, rejected) => {
    return axios[axiosType](axiosUrl, parameter, {
      cancelToken: new axios.CancelToken(function executor(c) {
        // executor 函数接收一个 cancel 函数作为参数
        allowCancel.cancel = c;
      })
    })
      .then(data => {
        // console.log('axios.then.data===========', data);
        let realData = data.data;

        if (!realData) {
          realData = {
            code: 3101,
            data: null,
            message: '返回为空'
          };
        } else if (typeof data.data !== 'object') {
          realData = {
            code: 3102,
            message: '返回的不是一个对象',
            data: data.data
          };
        } else if (
          data.code === 8500 ||
          data.code === 8501 ||
          data.code === 8503 ||
          data.code === 8504 ||
          data.code === 8505
        ) {
          realData = {
            code: 3103,
            message: 'Token错误或过期',
            data: data
          };
        }

        let code = realData.code;

        /***
         * Step 2 只有code==200时才返回数据
         * */
        if (code && code === 200) {
          // code!==200 但是需要返回后端数据给前端做自定义处理
          typeof self.success === 'function' && self.success(parameter, data);

          // 如果返回的data中有error字段，用originalError保存改值
          if (realData.error) {
            realData.originalError = realData.error;
          }

          realData.error = null;

          if (parameter.WAITING_PROMISE) {
            return resolved(callback(realData));
          } else {
            return resolved(callback({data: realData, error: null}));
            // return resolved(callback({...realData}));
          }
        } else if (code && parameter.ERROR_BACK) {
          // code!==200 但是需要返回请求数据给前端做自定义处理
          typeof self.success === 'function' && self.success(parameter, data);

          return resolved(callback({data: realData, error: code}));
        } else {
          // 处理异常
          data.data = realData;
          // 将异常抛给自己的catch
          throw data;
        }
      })
      .catch(function (error) {
        console.error('[=> api.js] Axios抛出错误 ::', error);
        if (axios.isCancel(error)) {
          typeof self.cancel === 'function' && self.cancel(parameter, error);
          return false;
        }
        // console.log('axios.catch', error);
        let newError = {...error};
        // axios自己抛出的错误
        if (error.request && error.request.response) {
          let response = error.request.response;

          try {
            response = JSON.parse(response);
          } catch (e) {
            console.warn('[-> api.js] JSON.parse(response) -- 后台返回数据解析失败！', response);
            response = {code: null, message: '返回数据解析失败！'};
          }

          newError.data = response;

        } else {
          newError.data = {code: null, message: error.message};
        }

        // 保留原始code
        newError.errorCode = newError.data.code;
        newError.message = newError.data.message;

        // 原始code无效时 使用status代替commitCode
        if (Number.isNaN(parseInt(newError.data.code))) {
          newError.errorCode = newError.status;
        }

        let consoleText = newError.status === 200 ? '后台返回code!==200' : 'Axios错误';
        console.error('[-> api.js] ' + consoleText + '：', newError);

        // 请求失败 防止loading锁死
        typeof self.fail === 'function' && self.fail(parameter, newError);
        if (parameter.CATCH_ERROR) {
          // 抛出错误 $ajax.gateway().then(resolved).catch(rejected);
          console.warn('[-> api.js] Error parameter.CATCH_ERROR');
          rejected(newError);
        } else if (parameter.ERROR_BACK) {
          // 强制返回请求数据给前端做自定义处理
          console.warn('[-> api.js] Error parameter.ERROR_BACK');
          resolved(callback({data: null, error: newError}));
        } else {
          if (parameter.WAITING_PROMISE) {
            console.warn('[-> api.js] Error has been captured, Promise is Pending, if u want to capture error, please transfer a parameter - CATCH_ERROR=true');
          } else {
            console.warn('[-> api.js] Promise is Resolved Error, if u want to catch error, please transfer a parameter - CATCH_ERROR=true');
            // resolved(callback({ result: {}, error: newError }));
            resolved(callback({data: null, error: newError}));
          }
        }
      });
  });
}

// 初始化
/**
 * 统一处理错误
 * parameter Object
 * parameter.CATCH_ERROR    Boolean
 *   false-默认 不抛出错误，promise处于Pending状态
 *   true-抛出错误，promise处于Rejected状态
 * parameter.WAITING_PROMISE
 *   code != 200 promise处于pending状态，

 */
let Api = {
  before: () => {
  },
  success: () => {
  },
  fail: () => {
  },
  cancel: () => {
  },
  /**
   * 返回一个promise对象，
   * 后台返回code == 200 promise处于resolved状态，
   * 后台返回code != 200 promise将一直处于pending状态，
   *     添加参数CATCH_ERROR/RESOLVE_ERROR可结束pending状态
   */
  gateway: function (apiName, parameter, allowCancel, callback) {
    let argumentsL = arguments.length;

    if (typeof apiName !== 'string') {
      console.error('[=> api.js] 请传入正确的apiName', apiName);
      return false;
    }

    if (typeof arguments[1] === 'object') {
      parameter = arguments[1];
    } else {
      parameter = {};
    }

    if (typeof arguments[2] === 'object') {
      allowCancel = arguments[2];
    } else {
      allowCancel = {};
    }

    if (typeof arguments[argumentsL - 1] === 'function') {
      callback = arguments[argumentsL - 1];
    } else {
      callback = result => result;
    }
    // 开始请求
    // 优先使用前端指定的请求类型
    let axiosType = parameter.METHOD || 'post';
    let axiosUrl = (config.publicPath || '') + config.serviceHost + apiName;
    if (axiosType === 'get') {
      // get请求时需拼接参数
      let queryUrl = param(parameter);
      if (queryUrl) axiosUrl = axiosUrl + '?' + queryUrl;
    }

    return getData.apply(this, [
      axiosUrl,
      axiosType,
      parameter,
      callback,
      allowCancel
    ]);
  },
  // 直接请求接口，不走微服务
  request(apiName, parameter, allowCancel, callback) {
    let argumentsL = arguments.length;

    if (typeof apiName !== 'string') {
      console.error('[=> api.js] 请传入正确的apiName', apiName);
      return false;
    }

    if (typeof arguments[1] === 'object') {
      parameter = arguments[1];
    } else {
      parameter = {};
    }

    if (typeof arguments[2] === 'object') {
      allowCancel = arguments[2];
    } else {
      allowCancel = {};
    }

    if (typeof arguments[argumentsL - 1] === 'function') {
      callback = arguments[argumentsL - 1];
    } else {
      callback = result => result;
    }

    // 开始请求
    // 优先使用前端指定的请求类型
    let axiosType = parameter.METHOD || 'post';
    let axiosUrl = apiName;

    return getData.apply(this, [
      axiosUrl,
      axiosType,
      parameter,
      callback,
      allowCancel
    ]);
  },
  // 纯净的axios
  axios
};

let api = Object.create(Api);
export default api;
